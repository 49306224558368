<template>
  <div class="ml-3 p-5">
    <slider-editor
      ref="sliderEditor"
      :navigation="true"
      :width="sliderWidth"
      :filePostAction="`${baseUrl}/api/files`"
      :formOptions="pictureFormOptions"
      v-model="row.Pictures"
      @add="onSliderEdit"
      @edit="onSliderEdit"
      @save="onSliderSave"
      @remove="onSliderRemove"
    />
  </div>
</template>

<script type="ts">
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import { watch } from "vue";

export default defineComponent({
  components: {
    SliderEditor,
  },
  props: {
    galleryId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;

    const sliderEditor = ref({});
    const sliderWidth = ref("100%");
    const gallery = reactive({id:0});
    const row = ref({ Pictures:[] });

    watch(
      () => props.galleryId,
      () => {
        if(props.galleryId > 0){
        gallery.id = props.galleryId;
        getPictureGallery(props.galleryId);
        }
      }
    );

    const pictureFormOptions = {
      titleWidth: 40,
      titleAlign: "right",
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 14,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 10,
          titleWidth: 40,
          itemRender: {
            name: "$input",
            props: { type: "number", placeholder: "請輸入數字" },
          },
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "清除" } },
            ],
          },
        },
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
      },
    };

    const getPictureGallery = (key) => {
      model?.dispatch("pictureGallery/find", key).then(
        (payload) => {
          console.log("getPictureGallery payload",payload);
          if (payload) {
            Object.assign(row, payload);
            sliderEditor.value.reload(row.Pictures);
          }
        },
        (failure) => {
          CloudFun.send("error", {
            subject: "取得產品照片集失敗",
            content: failure,
          });
        }
      );
    };

    return {
      getPictureGallery,
      gallery,
      row,
      sliderEditor,
      sliderWidth,
      pictureFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onSliderEdit(image, callback) {
      image.PictureGalleryId = this.gallery.id;
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch("picture/delete", image.Id);
      callback();
    },
    async onSliderSave(params, callback) {
      await this.$model.dispatch("picture/save", params).then(
        () => {

          this.$model.dispatch("pictureGallery/find", this.gallery.id).then(
            (entity) => {
              debugger;
              if (entity.Pictures)
                entity.Pictures = entity.Pictures.sort(
                  (a, b) => a.Ordinal - b.Ordinal
                );
              this.$refs.sliderEditor.reload(
                entity.Pictures.sort((a, b) => a.Ordinal - b.Ordinal)
              );
            },
            (reason) => {
              CloudFun.send("error", {
                subject: "重載失敗",
                content: reason,
              });
            }
          );
        },
        (reason) => {
          CloudFun.send("error", { subject: "保存失敗", content: reason });
        }
      );
      callback();
    },
  },
});
</script>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
