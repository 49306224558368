<template>
  <div>
    <dx-popup
      ref="modal"
      :visible="show"
      title="產品圖片上傳"
      width="50%"
      height="50%"
      @hidden="close"
    >
      <div calss="text-center">
        <file-uploader
          name="files"
          ref="uploader"
          extensions="png,gif,jpg,jpeg"
          accept="image/png,image/gif,image/jpeg"
          v-model="files"
          :action="postAction"
          :multiple="false"
          @filter="inputFilter"
          :id="id"
        >
        </file-uploader>
      </div>
      <div class="mt-3">
        <vxe-button
          status="success"
          content="上傳"
          round
          @click="save(editImage)"
        ></vxe-button>
      </div>
    </dx-popup>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, { defineComponent, ref, computed } from "@cloudfun/core";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { DxPopup } from "devextreme-vue";
export default defineComponent({
  components: {
    FileUploader,
    DxPopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    const uploader = ref({});
    const files = ref([]);
    const current = ref({});
    const modal = ref({});
    const editImage = ref({});
    const postAction = computed(() => {
      return `${process.env.VUE_APP_BACKEND_URL}/api/files`;
    });

    console.log("postAction", postAction);
    console.log("uploader", uploader);
    return {
      modal,
      uploader,
      files,
      current,
      editImage,
      postAction,
    };
  },

  watch: {
    data(newValue, oldValue) {
      this.current = newValue;
      this.editImage = this.current;
      if (this.editImage === null || undefined) this.editImage = { Uri: "" };
      //   console.log("data newValue", this.current);
      //   console.log("url", this.postAction);
    },
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (!newFile) return prevent();
      if (newFile && !oldFile) {
        if (!/\.(png|gif|jpg|jpeg)$/i.test(newFile.name)) {
          alert("未支援此種圖片格式");
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          console.log("nextTick");
        });
      }
      if (!newFile && oldFile) {
      }
    },
    deletePicture() {
      if (this.editImage.Id === 0) {
        // CloudFun.send('error', { subject: '操作失敗！', content: '請確認圖片是否存在！' })
        if (this.files.length > 0) this.removeFile();
        return;
      }
      this.$model
        .dispatch(`picture/deletePicture`, this.editImage.Id)
        .then(
          (paylod) => {
            CloudFun.send("info", {
              subject: "刪除成功！",
              content: "圖片刪除成功",
            });
            this.removeFile();
          },
          (failure) =>
            CloudFun.send("error", { subject: "操作失敗！", content: failure })
        )
        .finally();
    },
    removeFile() {
      console.log(this.files.length > 0);
      if (this.files.length > 0) this.files = [];
      this.editImage.Uri = "";
    },
    close() {
      this.files = [];
      this.$emit("update:show", false);
    },
    async save(image) {
      const params = {
        // id: this.current.Id,
        // types: [],
        updateRows: [],
      };
      if (this.uploader.files.length) {
        this.uploader.files.forEach((e) => {
          e.active = true;
        });
        await this.uploader.upload().then((files) => {
          files.forEach((file) => {
            image.Uri = file.response.payload?.length
              ? file.response.payload[0]
              : undefined;
            image.ContentType = file.type;
            params.updateRows.push({ ...image });
          });
        });
      } else if (image.Id) params.updateRows.push({ ...image });
      else {
        alert("請選擇上傳的照片");
        return;
      }
      const action = () => {
        this.files = [];
        this.uploader.reset();
        this.editImage = {};
        this.$emit("update:show", false);
      };
      if (this.$attrs.onSave) this.$emit("save", params, action);
      else action();
    },
  },
});
</script>

<style scoped></style>
