<template>
  <div>
    <dx-popup
      ref="sizeModal"
      :visible="show"
      :title="title"
      width="50%"
      height="Auto"
      @hidden="close"
    >
      <div class="h-full">
        <div class="w-full flex">
          <div class="w-full mx-5">
            <form @submit.prevent="onSubmit">
              <dx-form :form-data="formData">
                <dx-group-item :col-count="1">
                  <dx-simple-item
                    data-field="Product.Number"
                    editor-type="dxTextBox"
                    :label="{ text: '產品編號', alignment: 'right' }"
                    :editor-options="{ readOnly: true }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="Product.Name"
                    editor-type="dxTextBox"
                    :label="{ text: '產品名稱', alignment: 'right' }"
                    :editor-options="{ readOnly: true }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="Color.Name"
                    editor-type="dxTextBox"
                    :label="{ text: '顏色', alignment: 'right' }"
                    :editor-options="{ readOnly: true }"
                  >
                  </dx-simple-item>

                  <dx-simple-item
                    data-field="OneSize"
                    editor-type="dxCheckBox"
                    :label="{ text: '單一尺寸', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    col-span="2"
                    data-field="Sizes"
                    template="tags"
                    :label="{ text: '尺寸/規格', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  >
                  </dx-simple-item>
                  <dx-simple-item col-span="2">
                    <template #default>
                      <div class="grid justify-items-end">
                        <dx-button
                          :visible="canEdit"
                          icon="check"
                          text="儲存"
                          type="default"
                          validation-group="form"
                          use-submit-behavior="true"
                        ></dx-button>
                      </div>
                    </template>
                  </dx-simple-item>
                </dx-group-item>
                <template #tags="{}">
                  <dx-tag-box
                    :data-source="sizeDataSource"
                    v-model="sizes"
                    value-expr="Id"
                    display-expr="Name"
                    :read-only="!canEdit"
                    search-enabled="true"
                    multiline="true"
                    :accept-custom-value="false"
                  ></dx-tag-box>
                </template>
              </dx-form>
            </form>
          </div>
        </div>
      </div>
    </dx-popup>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, { defineComponent, ref, computed } from "@cloudfun/core";
import { DxPopup } from "devextreme-vue";
import { DxDataConfigurations } from "../../../../services/data-source-service";
import formatDate from "xe-utils/toDateString";
import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
  DxTagBox,
  DxSwitch,
  DxButton,
  DxLoadPanel,
} from "devextreme-vue";

import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";

export default defineComponent({
  components: {
    DxPopup,
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxTagBox,
    DxLabel,
    DxSwitch,
    DxButton,
    DxRequiredRule,
    DxLoadPanel,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const formData = ref({});
    const sizeModal = ref({});
    const canEdit = ref(true);
    const sizeDataSource = DxDataConfigurations.SizeDataConfiguration();
    const colorDataSource = DxDataConfigurations.ColorDataConfiguration({
      selectExpr: ["Id", "Name", "Number"],
    });
    const title = computed(
      () => `${props.data.Id && props.data.Id > 0 ? "修改" : "新增"}尺寸`
    );
    const sizes = ref([]);

    return {
      sizeModal,
      formData,
      title,
      canEdit,
      sizeDataSource,
      colorDataSource,
      sizes,
    };
  },

  watch: {
    data(newValue, oldValue) {
      this.formData = newValue;
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },

    onSubmit(e) {
      console.log("formData", this.formData);
      const params = {
        insertRows: [],
        updateRows: [],
      };
      console.log("sizes", this.sizes);
      if (this.sizes && this.sizes.length > 0) {
        this.sizes.forEach((el) => {
          const item = { ...this.formData, SizeId: el };
          console.log("item", item);
          if (this.formData.Id === 0) params.insertRows.push(item);
          else params.updateRows.push(item);
        });
      } else {
        const item = { ...this.formData, SizeId: null };
        debugger;
        if (this.formData.Id === 0) params.insertRows.push(item);
        else params.updateRows.push(item);
      }

      const action = () => {
        this.formData = {};
        this.$emit("update:show", false);
      };

      if (this.$attrs.onSave) this.$emit("save", params, action);
      else action();

      e.preventDefault();
    },
  },
});
</script>

<style scoped></style>
