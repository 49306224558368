<template>
  <div class="h-full w-full p-5" id="content">
    <div class="flex flex-row">
      <div class="intro-y box grid grid-rows-2 justify-items-center">
        <file-uploader
          id="product-photo-uri"
          class="w-48 h-48 border rounded"
          mode="image"
          :modelValue="formData.Photo?.Uri"
          :action="uploadAction"
          :autoUpload="true"
          @update:modelValue="
            (value) => {
              if (formData) {
                if (formData.Photo) formData.Photo.Uri = value;
                else formData.Photo = { Uri: value };
              }
            }
          "
        />
        <div class="flex flex-col">
          <div>銷售狀態</div>
          <div class="mt-2">
            <dx-switch
              v-model:value="formData.Published"
              switched-on-text="上架"
              switched-off-text="下架"
              width="60"
            ></dx-switch>
          </div>
        </div>
      </div>

      <div class="intro-y box flex-1 w-full">
        <div class="ml-5">
          <div>
            <form @submit.prevent="onSubmit">
              <dx-form :form-data="formData" validation-group="form">
                <dx-group-item :col-count="2">
                  <dx-simple-item
                    data-field="Number"
                    editor-type="dxTextBox"
                    :label="{ text: '產品編號', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  >
                    <DxRequiredRule></DxRequiredRule>
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="Name"
                    editor-type="dxTextBox"
                    :label="{ text: '產品名稱', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>
                  <dx-simple-item
                    :label="{ text: '產品分類', alignment: 'right' }"
                    col-span="2"
                    template="category"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>

                  <dx-simple-item
                    data-field="PurchasePrice"
                    editor-type="dxNumberBox"
                    :label="{ text: '採購成本', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit, min: 0 }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="MarketPrice"
                    editor-type="dxNumberBox"
                    :label="{ text: '市場價格', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit, min: 0 }"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>
                  <dx-simple-item
                    data-field="SalePice"
                    editor-type="dxNumberBox"
                    :label="{ text: '銷售金額', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit, min: 0 }"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>
                  <dx-empty-item></dx-empty-item>
                  <dx-simple-item
                    data-field="CountryId"
                    editor-type="dxSelectBox"
                    :label="{ text: '產地', alignment: 'right' }"
                    :editor-options="{
                      readOnly: !canEdit,
                      searchEnabled: true,
                      searchMode: 'contains',
                      searchExpr: 'Name',
                      displayExpr: 'Name',
                      valueExpr: 'Id',
                      showDataBeforeSearch: false,
                      dataSource: countryDataSource,
                    }"
                  >
                  </dx-simple-item>
                  <dx-simple-item
                    data-field="Ordinal"
                    editor-type="dxNumberBox"
                    :label="{ text: '排序值', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  >
                    <DxRequiredRule></DxRequiredRule
                  ></dx-simple-item>

                  <dx-simple-item
                    col-span="2"
                    data-field="Remark"
                    template="tags"
                    :label="{ text: '標籤', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  ></dx-simple-item>
                  <dx-simple-item
                    col-span="2"
                    data-field="Specification.Name"
                    editor-type="dxTextBox"
                    :label="{ text: '尺寸', alignment: 'right' }"
                    :editor-options="{
                      readOnly: !canEdit,
                      placeholder: '長mm x 寬mm x 高mm',
                    }"
                  ></dx-simple-item>
                  <dx-simple-item
                    col-span="2"
                    data-field="Remark"
                    template="materialTags"
                    :label="{ text: '材質', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  ></dx-simple-item>

                  <dx-simple-item
                    col-span="2"
                    data-field="Remark"
                    editor-type="dxTextArea"
                    :label="{ text: '備註', alignment: 'right' }"
                    :editor-options="{ readOnly: !canEdit }"
                  ></dx-simple-item>
                  <dx-simple-item col-span="2">
                    <template #default>
                      <div class="grid justify-items-end">
                        <dx-button
                          :visible="canEdit"
                          icon="check"
                          text="儲存"
                          type="default"
                          validation-group="form"
                          use-submit-behavior="true"
                        ></dx-button>
                      </div>
                    </template>
                  </dx-simple-item>
                </dx-group-item>

                <template #category="{}">
                  <dx-drop-down-box
                    v-model:value="selectedCategories"
                    :show-clear-button="true"
                    :data-source="categoryDataSource"
                    value-expr="Id"
                    :read-only="!canEdit"
                    display-expr="Name"
                    placeholder="選擇產品類別"
                    @value-changed="syncTreeViewSelection($event)"
                  >
                    <template #content="{}">
                      <dx-tree-view
                        :ref="treeView"
                        :data-source="categoryDataSource"
                        :select-by-click="true"
                        :select-nodes-recursive="true"
                        :search-enabled="true"
                        data-structure="plain"
                        key-expr="Id"
                        parent-id-expr="ParentId"
                        selection-mode="multiple"
                        show-check-boxes-mode="normal"
                        display-expr="Name"
                        @item-selection-changed="treeViewSelectChanged($event)"
                      />
                    </template>
                  </dx-drop-down-box>
                </template>
                <template #tags="{}">
                  <dx-tag-box
                    v-model="tags"
                    :data-source="tagDataSource"
                    display-expr="Name"
                    :read-only="!canEdit"
                    :search-enabled="true"
                    multiline="true"
                    :accept-custom-value="true"
                    @custom-item-creating="customTagItemCreate($event)"
                  ></dx-tag-box>
                </template>
                <template #materialTags="{}">
                  <dx-tag-box
                    :data-source="materialDataSource"
                    v-model="materials"
                    display-expr="Name"
                    :read-only="!canEdit"
                    :search-enabled="true"
                    multiline="true"
                    :accept-custom-value="true"
                    @custom-item-creating="customMaterialTagItemCreate($event)"
                  ></dx-tag-box>
                </template>
              </dx-form>
            </form>
          </div>
        </div>
        <!-- <div class="mt-3"></div> -->
      </div>
    </div>
  </div>
  <dx-load-panel
    v-model:visible="loading.visible"
    :position="loading.position"
    show-indicator="true"
    shading="true"
    showPane="true"
    shading-color="rgba(0,0,0,0.1)"
  ></dx-load-panel>
  <photo-upload
    :data="formData?.Photo"
    :show="photoUploadShow"
    id="main"
    @update:show="photoUploadShow = $event"
    @save="onPhotoSave"
  ></photo-upload>
</template>

<script>
/* eslint-disable */
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import { reactive, watch } from "vue";
import {
  DxDataConfigurations,
  DxDataSources,
  DxStores,
} from "../../../services/data-source-service";
import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
  DxTagBox,
  DxSwitch,
  DxButton,
  DxLoadPanel,
} from "devextreme-vue";

import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxEmptyItem,
  DxGroupItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";

import CfLoadingPanel from "@/cloudfun/components/CfLoadingPanel.vue";
import { DxValidationRule } from "devextreme-vue/form";
import PhotoUpload from "../photo/Upload.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

export default defineComponent({
  components: {
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxTagBox,
    DxLabel,
    DxSwitch,
    DxButton,
    CfLoadingPanel,
    DxRequiredRule,
    PhotoUpload,
    DxLoadPanel,
    DxEmptyItem,
    FileUploader,
  },
  props: {
    // id: { type: Number, required: true },
    data: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const treeView = ref({});
    const selectedCategories = ref([]);
    const tags = ref([]);
    const materials = ref([]);
    const formData = ref({});
    const canEdit = ref(true);
    const photoUploadShow = ref(false);

    const loading = reactive({ visible: false, position: { of: "#content" } });
    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });

    const categoryDataSource = DxDataConfigurations.CategoryDataConfiguration({
      selectExpr: ["Id", "Name", "ParentId"],
    });
    const countryDataSource = DxDataConfigurations.CountryDataConfiguration();
    const warehouseDataSource = DxDataConfigurations.WarehouseDataConfiguration();
    const erpMainCategoryDataSource = DxDataConfigurations.ErpProductCategoryDataConfiguration();
    const erpSubCategoryDataSource = DxDataConfigurations.ErpProductSubCategoryDataConfiguration();
    const tagDataSource = DxDataConfigurations.TagDataConfiguration({
      selectExpr: ["Name"],
    });
    const materialDataSource = DxDataConfigurations.MaterialTagDataConfiguration(
      { selectExpr: ["Name"] }
    );

    watch(
      () => props.data,
      () => {
        syncData(props.data);
      }
    );

    const syncData = (data) => {
      // loading.visible = true;
      formData.value = data;
      selectedCategories.value = [];
      tags.value = [];
      materials.value = [];
      if (data.Id > 0) {
        model
          .dispatch("product/find", data.Id)
          .then(
            (resp) => {
              console.log("Resp", resp);
              formData.value = resp;
              selectedCategories.value = resp.Categories.map(
                (el) => el.CategoryId
              );
              tags.value = resp.Tags.map((el) => {
                console.log("el", el);
                return { Name: el.Tag.Name };
              });
              materials.value = resp.Materials.map((el) => {
                return {
                  Name: el.MaterialTag.Name,
                };
              });

              console.log("tags", tags.value);
            },
            (error) => {
              console.log("error", error);
            }
          )
          .finally(() => (loading.visible = false));
      } else {
        // if (data.Categories)
        //   selectedCategories.value = data.Categories.map((el) => el.CategoryId);
        // if (data.Tags) tags.value = data.Tags.map((el) => el.TagId);
      }
    };

    const syncTreeViewSelection = (e) => {
      const instance =
        (e.component.selectItem && e.component) ||
        (treeView && treeView.instance);

      if (instance) {
        if (e.value === null) {
          instance.unselectAll();
        } else {
          let values = e.value || treeView;
          values &&
            values.forEach(function (value) {
              instance.selectItem(value);
            });
        }
      }
    };
    const treeViewSelectChanged = (e) => {
      console.log("e", e.component.getSelectedNodeKeys());
      selectedCategories.value = e.component.getSelectedNodeKeys();
    };

    const customTagItemCreate = (e) => {
      console.log("customTagItemCreate", e.text);
      tags.value.push({ Name: e.text });
    };

    const customMaterialTagItemCreate = (e) => {
      console.log("customMaterialTagItemCreate", e.text);
      materials.value.push({ Name: e.text });
    };

    const customTagItemClick = (e) => {
      console.log("item click", e);
    };

    const customMaterialTagItemClick = (e) => {
      console.log("item click", e);
    };
    const onSubmit = (e) => {
      formData.value.Categories = selectedCategories.value.map((el) => {
        return { CategoryId: el, ProductId: formData.value.Id };
      });

      formData.value.Tags = tags.value.map((el) => {
        return {
          Tag: { Name: el.Name },
          ProductId: formData.value.Id,
        };
      });

      formData.value.Materials = materials.value.map((el) => {
        return {
          MaterialTag: { Name: el.Name },
          ProductId: formData.value.Id,
        };
      });

      console.log("formData", formData.value);
      console.log("tags", tags.value);
      loading.visible = true;

      if (formData.value.Id == 0) params.insertRows = [formData.value];
      else params.updateRows = [formData.value];

      model
        .dispatch("product/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            emit("refresh");
            CloudFun.send("info", {
              subject: formData.value.Id == 0 ? "新增成功" : "更新成功",
              content:
                formData.value.Id == 0
                  ? "產品資料新增完成"
                  : "產品資料更新完成",
            });
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally(() => (loading.visible = false));

      e.preventDefault();
    };

    const editPhoto = () => {
      photoUploadShow.value = true;
    };

    return {
      onSubmit,
      editPhoto,
      canEdit,
      loading,
      countryDataSource,
      warehouseDataSource,
      treeViewSelectChanged,
      syncTreeViewSelection,
      formData,
      params,
      selectedCategories,
      categoryDataSource,
      erpMainCategoryDataSource,
      erpSubCategoryDataSource,
      materialDataSource,
      tagDataSource,
      tags,
      materials,
      photoUploadShow,
      customTagItemCreate,
      customMaterialTagItemCreate,
      customTagItemClick,
      customMaterialTagItemClick,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    async onPhotoSave(params, callback) {
      await this.$model
        .dispatch("picture/productSave", { id: this.formData.Id, ...params })
        .then(
          () => {
            this.$model.dispatch("product/find", this.formData.Id).then(
              (entity) => {
                this.formData = entity;
                CloudFun.send("info", {
                  subject: "上傳成功",
                  content: "圖片上傳成功",
                });
                console.log("entity", entity);
              },
              (reason) => {
                debugger;
                CloudFun.send("error", {
                  subject: "圖片上傳失敗",
                  content: reason,
                });
              }
            );
          },
          (reason) => {
            CloudFun.send("error", { subject: "保存失敗", content: reason });
          }
        );
      callback();
    },
  },
});
</script>

<style scoped></style>
