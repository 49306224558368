<template>
  <!-- BEGIN: HTML Table Data -->
  <div>
    <div class="grid grid-cols-12 gap-6 mt-8">
      <div class="col-span-12 lg:col-span-4 xxl:col-span-3">
        <h2 class="intro-y text-lg font-medium mr-auto mt-2">產品顏色/尺寸</h2>
        <!-- BEGIN: File Manager Menu -->
        <div class="intro-y box p-5 mt-0">
          <a
            href="javascript:void()"
            class="flex items-center px-3 py-2 mt-2 rounded-md"
            @click="newColor"
          >
            <PlusIcon class="w-4 h-4 mr-2" /> 新增顏色
          </a>
          <div class="border-t border-gray-200 dark:border-dark-5 mt-5 pt-5">
            <div
              class="flex items-center px-3 py-2 rounded-md"
              v-for="(item, index) in productColors"
              :key="index"
              @click="selectColor(item)"
              :class="[
                currentColor.Id === item.Id ? 'bg-theme-1 text-white' : '',
              ]"
            >
              <!-- <div
                class="w-5 h-3 rounded-full mr-3 border"
                :style="`background-color:${item.Color.HexValue}`"
              ></div> -->
              <div>&nbsp;</div>
              <div class="w-full">
                {{ item.Color.Name }}
              </div>
              <div class="flex justify-end w-24">
                <button
                  class="button rounded-full mr-1 bg-blue-600 text-white"
                  title="編輯"
                  @click.stop="editColor(item)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>

                <button
                  class="button rounded-full bg-red-600 text-white"
                  title="刪除"
                  @click.stop="removeColor(item)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END: File Manager Menu -->
      </div>
      <div class="col-span-12 lg:col-span-8 xxl:col-span-9">
        <!-- BEGIN: File Manager Filter -->
        <div class="flex justify-between items-bottom mb-2">
          <file-uploader
            id="product-item-photo-uri"
            class="border rounded w-24 h-24"
            mode="image"
            :modelValue="currentColor?.Picture?.Uri"
            :action="uploadAction"
            :autoUpload="true"
            :defaultImage="'https://via.placeholder.com/96x96.png'"
            @update:modelValue="
              (value) => {
                if (currentColor) {
                  if (currentColor.Picture) currentColor.Picture.Uri = value;
                  else currentColor.Picture = { Uri: value };
                }
              }
            "
            @uploaded="
              onPhotoSave({ updateRows: [currentColor.Picture] }, () => {})
            "
          />
          <div class="flex items-end align-bottom">
            <button
              class="button text-white bg-theme-1 shadow-md mr-2"
              @click="newSize"
            >
              新增尺寸
            </button>
          </div>
        </div>
        <!-- END: File Manager Filter -->
        <!-- BEGIN: Directory & Files -->
        <div class="w-full">
          <grid
            ref="grid"
            v-bind="gridOptions"
            @addNewRow="
              (row, callback) => {
                initData(row);
                callback();
              }
            "
            @edit="onGridEdit"
            @modalResize="onGridModalResize"
            @removeSelectedRows="onGridRemoveSelectedRows"
          >
            <!-- <template #toolbar>
              <div class="flex py-2">
                <vxe-button
                  status="success"
                  icon="vxe-icon--plus"
                  @click="grid.addNewRow"
                  >新增帳號</vxe-button
                >
              </div>
            </template> -->

            <template #modal="{ row, submit, reset }">
              <vxe-form
                :title-width="100"
                title-align="right"
                v-bind="formOptions"
                :data="row"
                @reset="reset"
                @submit="submit"
              >
              </vxe-form>
            </template>
            <template #published="{ row }">
              <vxe-switch
                v-model="row.Published"
                open-label="有"
                close-label="無"
              ></vxe-switch>
            </template>
          </grid>
        </div>
        <!-- END: Directory & Files -->
      </div>
    </div>

    <!-- <div class="overflow-x-auto scrollbar-hidden mt-2">
      <div>{{ title }}</div>

      <div>
        <div class="border-t border-gray-200 dark:border-dark-5 mt-5 pt-5">
          <a href="" class="flex items-center px-3 py-2 rounded-md">
            <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
            Custom Work
          </a>
          <a href="" class="flex items-center px-3 py-2 mt-2 rounded-md">
            <div class="w-2 h-2 bg-theme-9 rounded-full mr-3"></div>
            Important Meetings
          </a>
          <a href="" class="flex items-center px-3 py-2 mt-2 rounded-md">
            <div class="w-2 h-2 bg-theme-12 rounded-full mr-3"></div>
            Work
          </a>
          <a href="" class="flex items-center px-3 py-2 mt-2 rounded-md">
            <div class="w-2 h-2 bg-theme-11 rounded-full mr-3"></div>
            Design
          </a>
          <a href="" class="flex items-center px-3 py-2 mt-2 rounded-md">
            <div class="w-2 h-2 bg-theme-6 rounded-full mr-3"></div>
            Next Week
          </a>
          <a href="" class="flex items-center px-3 py-2 mt-2 rounded-md">
            <PlusIcon class="w-4 h-4 mr-2" /> Add New Label
          </a>
        </div>
      </div>
     
    </div> -->
    <color-edit
      v-model:data="editItem"
      v-model:show="colorModalVisible"
      @update:show="() => (colorModalVisible = $event)"
      @save="onColorSave"
    ></color-edit>
    <size-edit
      v-model:data="editSizeItem"
      v-model:show="sizeModalVisible"
      @update:show="() => (sizeModalVisible = $evnet)"
      @save="onSizeSave"
    >
    </size-edit>
    <photo-upload
      :data="currentColor?.Picture"
      :show="photoUploadVisible"
      id="color"
      @update:show="photoUploadVisible = $event"
      @save="onPhotoSave"
    ></photo-upload>
  </div>
  <!-- END: HTML Table Data -->
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import { computed, onMounted, reactive, watch, nextTick } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import ColorEdit from "./modal/Edit";
import SizeEdit from "./modal/Size";
import PhotoUpload from "../photo/Upload.vue";
// import ColorItem from "./ColorItem.vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

export default defineComponent({
  components: {
    Grid,
    ColorEdit,
    SizeEdit,
    PhotoUpload,
    FileUploader,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const productColors = reactive([]);
    const currentColor = reactive({});
    const files = ref([]);
    const colorModalVisible = ref(false);
    const sizeModalVisible = ref(false);
    const photoUploadVisible = ref(false);
    const editItem = reactive({});
    const product = ref({});
    const editSizeItem = reactive({});
    const imageUrl = computed(() => {
      if (
        currentColor === undefined ||
        currentColor === null ||
        currentColor.Picture === null ||
        currentColor.Picture === undefined ||
        currentColor?.Picture?.Uri === null
      )
        return "https://via.placeholder.com/96x96.png";
      else {
        return currentColor.Picture.Uri;
      }
    });
    watch(
      () => props.data,
      () => {
        getColors(props.data.Id);
        product.value = props.data;
        console.log("props.product", product.value);
      }
    );

    const gridOptions = {
      title: "商品尺寸",
      canCreateRow: true,
      canUpdateRow: true,
      multiselect: false,

      columns: [
        {
          field: "Number",
          title: "貨號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Size.Name",
          title: "尺寸",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "JANCode",
          title: "JAN",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Published",
          title: "庫存",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          resizable: false,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
          slots: { default: "published" },
        },
        // {
        //   field: "LoginTime",
        //   title: "登入時間",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   //   editRender: {
        //   //     name: "$input",
        //   //     immediate: true,
        //   //     attrs: { type: "text" },
        //   //   },
        // },
      ],
      promises: {
        query: model
          ? (params) =>
              model.dispatch("productItemSize/queryByProductItem", {
                key: currentColor.Id,
                ...params,
              })
          : undefined,
        queryAll: model
          ? () => model.dispatch("productItemSize/queryByProductItem", props.id)
          : undefined,
        save: model
          ? (params) => model.dispatch("productItemSize/save", params)
          : undefined,
      },
      modalConfig: { height: "Auto", width: "500" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "貨號",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Size.Name",
          title: "尺寸",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", readonly: true },
          },
        },
        {
          field: "JANCode",
          title: "JAN",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        // {
        //   field: "Password",
        //   title: "密碼",
        //   span: 24,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },

        // {
        //   field: "Email",
        //   title: "Email",
        //   span: 24,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入帳號名稱",
          },
        ],
        // Account: [
        //   {
        //     type: "string",
        //     max: 128,
        //     required: true,
        //     message: "請輸入帳號",
        //   },
        // ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row) => {
      row.Id = 0;
      row.Number = "";
      // row.Account = "";
      // row.Password = "";
      // row.OrganizationId = props.id;
    };

    onMounted(() => {
      console.log("");
    });

    const getColors = (key) => {
      productColors.length = 0;
      // if (key === 0) {
      //   currentColor.value = {};

      // }
      model?.dispatch("productItem/getByProduct", key).then(
        (payload) => {
          console.log("productColors", productColors);
          productColors.push(...payload);

          if (productColors.length === 0) {
            Object.assign(currentColor, { Id: 0 });
          } else {
            Object.assign(currentColor, productColors[0]);
          }

          grid.value.reload();
        },
        (failure) =>
          CloudFun.send("error", {
            subject: "操作失敗！",
            content: failure.message,
          })
      );
    };

    const selectColor = (item) => {
      Object.assign(currentColor, item);
      grid.value.reload();
    };

    return {
      initData,
      getColors,
      selectColor,
      imageUrl,
      currentColor,
      product,
      grid,
      gridOptions,
      formOptions,
      productColors,
      files,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      colorModalVisible,
      sizeModalVisible,
      photoUploadVisible,
      editItem,
      editSizeItem,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("productItemSize/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    newSize() {
      this.sizeModalVisible = true;
      console.log("currentColor", this.currentColor);
      this.editSizeItem = {
        Id: 0,
        Number: "",
        ProductId: this.product.Id,
        Product: {
          Id: this.product.Id,
          Name: this.product.Name,
          Number: this.product.Number,
        },
        ColorId: this.currentColor.ColorId,
        Color: {
          Id: this.currentColor.ColorId,
          Name: this.currentColor.Color.Name,
          Number: this.currentColor.Color.Number,
        },
        ProductItemId: this.currentColor.Id,
        OneSize: true,
        Sizes: [],
      };

      console.log("this.editSizeItem", this.editSizeItem);
    },
    newColor() {
      this.editItem = {
        Id: 0,
        Number: "",
        ProductId: this.product.Id,
        Product: {
          Id: this.product.Id,
          Name: this.product.Name,
          Number: this.product.Number,
        },
        ColorId: 0,
        PictureId: 0,
        Picture: { Uri: "" },
      };
      console.log("editItem", this.editItem);
      this.colorModalVisible = true;
    },
    async editColor(row) {
      console.log("product", this.product);
      const payload = await this.$model.dispatch("productItem/find", row.Id);
      if (payload) {
        console.log("payload", payload);
        this.editItem = payload;
      }
      this.colorModalVisible = true;
    },
    removeColor(row) {
      if (confirm("確定刪除!?")) {
        console.log("row", row);
        const params = { deleteRows: [row] };
        this.$model
          .dispatch("productItem/save", params)
          .then(
            (resp) => {
              this.getColors(this.data.Id);
              CloudFun.send("info", {
                subject: "刪除成功",
                content: "產品顏色刪除完成",
              });
            },
            (failure) => {
              console.log("update error", failure);
              CloudFun.send("error", {
                subject: "操作失敗！",
                content: failure,
              });
            }
          )
          .finally();
      }
    },
    onColorSave(params, callback) {
      this.$model
        .dispatch("productItem/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            this.getColors(this.data.Id);
            CloudFun.send("info", {
              subject: params.insertRows.length > 0 ? "新增成功" : "更新成功",
              content:
                params.insertRows.length > 0
                  ? "產品顏色新增完成"
                  : "產品顏色更新完成",
            });
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();
      callback();
    },
    onSizeSave(params, callback) {
      console.log("params", params);

      this.$model
        .dispatch("productItemSize/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            // this.getColors(this.data.Id);
            this.grid.reload();
            CloudFun.send("info", {
              subject: params.insertRows.length > 0 ? "新增成功" : "更新成功",
              content:
                params.insertRows.length > 0
                  ? "產品尺寸新增完成"
                  : "產品尺寸更新完成",
            });
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();
      callback();
    },
    uploadColorPhoto() {
      this.photoUploadVisible = true;
    },
    onPhotoSave(params, callback) {
      this.$model
        .dispatch("picture/colorSave", { id: this.currentColor.Id, ...params })
        .then(
          () => {
            this.getColors(this.product.Id);
            CloudFun.send("info", {
              subject: "上傳成功",
              content: "圖片上傳成功",
            });
            // this.$model.dispatch("product/find", this.product.Id).then(
            //   (entity) => {
            //     this.formData = entity;
            //     CloudFun.send("info", {
            //       subject: "上傳成功",
            //       content: "圖片上傳成功",
            //     });
            //     console.log("entity", entity);
            //   },
            //   (reason) => {
            //     debugger;
            //     CloudFun.send("error", {
            //       subject: "圖片上傳失敗",
            //       content: reason,
            //     });
            //   }
            // );
          },
          (reason) => {
            CloudFun.send("error", { subject: "保存失敗", content: reason });
          }
        );
      callback();
    },
  },
});
</script>
