<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">產品管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="addNew"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增產品
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <form class="xl:flex sm:mr-auto" @submit.prevent="onFilter">
              <!-- <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
                <select
                  class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
                  v-model="selectedCategory"
                  @change="grid.refresh"
                >
                  <option value="">請選擇類別</option>
                  <option
                    v-for="(option, index) in category"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div> -->

              <div class="sm:flex items-center sm:mr-2 mt-2 xl:mt-0">
                <input
                  type="text"
                  class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
                  v-model="keyword"
                  placeholder="請輸入關鍵字"
                  @keyup.enter="grid.refresh"
                />
              </div>
            </form>
            <!-- <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            /> -->
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
          @currentRowChanged="onGridCurrentRowChanged"
        >
          <!-- <template #operate="{ row }">
            <slot name="operate" :row="row">
              <div class="flex" v-if="!isEditing">
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-1 text-white"
                  title="編輯"
                  @click="edit(row)"
                >
                  <FontAwesome icon="highlighter" class="w-4 h-4" />
                </button>
                <button
                  class="button rounded-full mr-1 mb-2 bg-theme-6 text-white"
                  title="取消"
                  @click="remove(row)"
                >
                  <FontAwesome icon="trash" class="w-4 h-4" />
                </button>
              </div>
            </slot>
          </template> -->

          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #published="{ row }">
            <vxe-switch
              v-model="row.Published"
              open-label="上架"
              close-label="下架"
              @change="switchChange(row, $event)"
            ></vxe-switch>
            <!-- <dx-switch
              switched-off-text="下架"
              switched-on-text="上架"
              width="50"
              v-model:value="row.Published"
            ></dx-switch> -->
          </template>
        </grid>
      </div>
      <div class="post intro-y overflow-hidden box mt-5">
        <div
          class="post__tabs nav-tabs flex flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
        >
          <Tippy
            tag="a"
            content="顯示產品資訊"
            data-toggle="tab"
            data-target="#info"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center active"
          >
            <UsersIcon class="w-4 h-4 mr-2" /> 產品資訊
          </Tippy>
          <Tippy
            tag="a"
            content="顯示產品顏色"
            data-toggle="tab"
            data-target="#store"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          >
            <UsersIcon class="w-4 h-4 mr-2" /> 產品顏色
          </Tippy>
          <Tippy
            tag="a"
            content="產品介紹說明內容維護"
            data-toggle="tab"
            data-target="#description"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          >
            <TruckIcon class="w-4 h-4 mr-2" /> 產品介紹說明
          </Tippy>
          <Tippy
            tag="a"
            content="顯示經銷商目前購物車內容"
            data-toggle="tab"
            data-target="#pictureGallery"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          >
            <ShoppingCartIcon class="w-4 h-4 mr-2" /> 產品圖集
          </Tippy>
        </div>
        <div class="post__content tab-content">
          <div id="info" class="tab-content__pane px-5 active">
            <!-- <div
              v-show="!(masterRow.Id > 0)"
              class="h-16 flex items-center m-4"
            >
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> 請選取商品資料
              </div>
            </div> -->
            <div>
              <info
                :data="masterRow"
                @refresh="
                  () => {
                    grid.reload();
                  }
                "
              ></info>
            </div>
          </div>

          <div id="store" class="tab-content__pane px-5">
            <div
              v-show="!(masterRow.Id > 0)"
              class="h-16 flex items-center m-4"
            >
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> 請選取商品資料
              </div>
            </div>
            <div v-show="masterRow.Id > 0">
              <color-grid :data="masterRow" :title="detailTitle"></color-grid>
            </div>
          </div>

          <div id="description" class="tab-content__pane px-5">
            <div
              v-show="!(masterRow.Id > 0)"
              class="h-16 flex items-center m-4"
            >
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> order
              </div>
            </div>
            <div v-show="masterRow.Id > 0">
              <description :id="masterRow.Id"></description>
            </div>
          </div>
          <div id="pictureGallery" class="tab-content__pane px-5">
            <!-- <div class="h-16 flex items-center m-4">
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> shoppingCart
              </div>
            </div> -->
            <picture-gallery
              :galleryId="masterRow.PictureGalleryId"
            ></picture-gallery>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import { onMounted } from "vue";
import CloudFun, {
  defineComponent,
  ref,
  Condition,
  Operator,
} from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import ColorGrid from "./color/Main";
import Description from "./description/Main";
import PictureGallery from "./photo/Main";
import Info from "./info/Main";
// import { DxSwitch } from "devextreme-vue";

export default defineComponent({
  components: {
    Grid,
    ColorGrid,
    Description,
    PictureGallery,
    Info,
    // DxSwitch,
  },
  setup() {
    const model = CloudFun.current?.model;
    const masterRow = ref({});
    const grid = ref({});
    const detailTitle = ref({});
    const category = ref([]);
    const selectedCategory = ref({});
    const keyword = ref("");

    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions = {
      title: "經銷商",
      canCreateRow: true,
      canUpdateRow: false,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      // pagerConfig: {
      //   currentPage: 1,
      //   pageSize: 10,
      //   pageSizes: [5, 10, 20],
      //   layouts: [
      //     "PrevJump",
      //     "PrevPage",
      //     "Jump",
      //     "PageCount",
      //     "NextPage",
      //     "NextJump",
      //     "Sizes",
      //     "Total",
      //   ],
      // },
      printConfig: {
        sheetName: "經銷商清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "經銷商清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Number",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          fixed: "left",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          minWidth: "150",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "ErpProductCategory.Name",
          title: "商品大類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "200",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "ErpProductSubCategory.Name",
          title: "商品中類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "200",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Country.Name",
          title: "產地",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Warehouse.Name",
          title: "倉庫",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "180",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Ordinal",
          title: "排序值",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Published",
          title: "上架",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "100",
          resizable: false,
          // editRender: {
          //   name: "$input",
          //   immediate: true,
          //   attrs: { type: "text" },
          // },
          slots: { default: "published" },
        },

        // {
        //   field: "Fax",
        //   title: "傳真號碼",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        // {
        //   field: "Email",
        //   title: "Email",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
        // {
        //   field: "Address.Line",
        //   title: "地址",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true,
        //   editRender: {
        //     name: "$input",
        //     immediate: true,
        //     attrs: { type: "text" },
        //   },
        // },
      ],
      promises: {
        query: model
          ? (params) => {
              if (selectedCategory.value > 0)
                params.condition = new Condition(
                  "ErpProductCategoryId",
                  Operator.Equal,
                  selectedCategory.value
                );

              if (keyword.value) params.keyword = keyword.value;

              return model.dispatch("product/query", params);
            }
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("product/query", {
                keyword: grid.value.keyword,
              })
          : undefined,
        save: model
          ? (params) => model.dispatch("product/save", params)
          : undefined,
      },
      modalConfig: { height: "Auto" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "產品編號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Name",
          title: "產品名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        // {
        //   field: "TaxId",
        //   title: "統一編號",
        //   span: 12,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        // {
        //   field: "Principal.Name",
        //   title: "負責人姓名",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     itemRender: {
        //       name: "$input",
        //       props: { placeholder: "請輸入文字" },
        //     },
        //   },
        // },
        // {
        //   field: "Phone",
        //   title: "電話號碼",
        //   span: 12,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        // {
        //   field: "Fax",
        //   title: "傳真號碼",
        //   span: 12,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        // {
        //   field: "Email",
        //   title: "Email",
        //   span: 12,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        // {
        //   field: "Address.Line",
        //   title: "地址",
        //   span: 24,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入產品名稱",
          },
        ],
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入產品編號",
          },
        ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row) => {
      row.Id = 0;
      row.Name = "";
      row.Number = "";
    };

    const getCategory = async () => {
      const payload = await model.dispatch("erpProductCategory/query", null);
      category.value = payload.data.map((el) => {
        return { label: el.Name, value: el.Id };
      });
    };

    const switchChange = async (row, $event) => {
      const payload = await model?.dispatch("product/find", row.Id);
      if (payload) {
        payload.Published = $event.value;
        await model?.dispatch("product/updatePublish", payload);
      }
    };

    const addNew = () => {
      // masterRow.value.Id = 0;
      // masterRow.value.Number = "";
      // masterRow.value.Name = "";
      masterRow.value = {
        Id: 0,
        Number: "",
        Name: "",
      };
      grid.value.insert(masterRow);
    };

    // const addNewRow = (row?: any) => {
    //   if (!props.canCreateRow || editingRow.value) return;
    //   if (row === undefined) row = {};
    //   const action = () => {
    //     instance.value.insert(row).then(data => edit(data.row));
    //   };
    //   if (attrs.onAddNewRow) emit('addNewRow', row, action);
    //   else action();
    // };

    onMounted(() => {
      console.log("");
      getCategory();
    });

    return {
      addNew,
      initData,
      switchChange,
      category,
      keyword,
      getCategory,
      selectedCategory,
      masterRow,
      detailTitle,
      grid,
      gridOptions,
      formOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("product/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    onGridCurrentRowChanged(row) {
      row.TreeSelected = [1, 2];
      this.masterRow = row;

      // this.detailTitle = `${this.masterRow.Number} / ${this.masterRow.Name}`;
    },
  },
});
</script>
